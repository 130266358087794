import React, { useEffect, useRef, useState } from "react";
import about1 from "../images/aboutimage1.png";
import about3 from "../images/aboutimage2.png";
import about2 from "../images/aboutimage3.png";
import CountUp from "react-countup";
import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const sectionRef = useRef(null)
  
  useEffect(()=>{
    if (sectionRef.current){
      sectionRef.current.scrollIntoView({behavior:"smooth"})
    }
  },[]);

  return (
    <section ref={sectionRef}>
    <div className="bg-[#345fe0dd] h-16"></div>
      <div className="relative text-[#262e45bb]">
        <div className="flex items-center p-4 sm:p-10 w-[100%] flex-col-reverse sm:flex-row justify-around sm:sticky top-0 min-h-[280vh]  ">
          <div className="  sm:w-[40%] flex flex-col  justify-center gap-5">
            <h1 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            Privacy Policy
            </h1>
            <p className="text-justify sm:text-left">
            Welcome to Social Syncing Technologies. At Social Syncing Technologies, 
            we value your privacy and are committed to protecting your 
            personal information. This Privacy Policy explains how we 
            collect, use, share, and protect your personal data when you use our website, 
            engage with our services, or interact with us.
            </p>
            <br/>
            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            1. Information We Collect:
            </h3>
            <br/>
            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            1.1. Information You Provide:
            </h4>
            
            <p className="text-justify sm:text-left">
            When you engage with us, you may voluntarily provide personal information, such as your name, email address, phone number, and other contact details.
            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            1.2. Information We Automatically Collect:
            </h4>
            
            <p className="text-justify sm:text-left">
            We may collect certain information automatically when you visit our website or use our services, including your IP address, device information, browser type, and usage data.
            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            2. How We Use Your Information:
            </h4>
            
            <p className="text-justify sm:text-left">
            We use the collected information for the following purposes:

To provide and maintain our services.
To respond to your inquiries and communicate with you.
To personalize your experience and improve our website.
To send periodic emails and updates about our services.
To comply with legal obligations and protect our rights.

            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            3. Sharing Your Information:
            </h4>
            
            <p className="text-justify sm:text-left">
            We may share your personal information with:

Service providers and partners who assist us in delivering our services.
Legal authorities when required by law or to protect our rights.
Affiliates and subsidiaries as needed for business operations.

            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            4. Data Security:
            </h4>
            
            <p className="text-justify sm:text-left">
            We take reasonable steps to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission or storage method can be guaranteed to be 100% secure.

            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            5. Your Choices:
            </h4>
            
            <p className="text-justify sm:text-left">
            You have the following rights regarding your personal information:

Access: You can request access to your personal data we hold.
Correction: You can request corrections to inaccuracies in your data.
Deletion: You can request the deletion of your personal information.
Opt-out: You can unsubscribe from our marketing communications.

            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            6. Cookies:
            </h4>
            
            <p className="text-justify sm:text-left">
            We use cookies and similar tracking technologies to enhance your browsing experience. You can manage your cookie preferences through your browser settings.

            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            7. Third-Party Links:
            </h4>
            
            <p className="text-justify sm:text-left">
            Our website may contain links to third-party websites. We are not responsible for their privacy practices, and we encourage you to review their Privacy Policies.

            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            8. Changes to This Privacy Policy:
            </h4>
            
            <p className="text-justify sm:text-left">
            We may update this Privacy Policy periodically to reflect changes in our practices and legal requirements. The updated version will be posted on our website with the effective date.

            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            9. Contact Us:
            </h4>
            
            <p className="text-justify sm:text-left">
            If you have any questions, concerns, or requests regarding this Privacy Policy or the use of your personal information, please contact us at info@Social Syncing.in.

            </p>
            <p className="text-justify sm:text-left">
            By using our website or services, you agree to the terms outlined in this Privacy Policy.

            </p>

            
           
          
            
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
