import React, { useEffect, useState } from "react";
import { BsCodeSlash } from "react-icons/bs";
import "./ServiceCard.css";

const ServiceCard = (props) => {
  const [type, setType] = useState("s_card");
  useEffect(() => {
    setType(props.type);
    console.log(type);
  }, [props.type]);
  return (
    <div
      className={`flex flex-col items-center gap-5 py-8 px-8 md:px-14 ${type}`}
    >
      <div className="flex justify-center items-center bg-[#262e45] text-white  w-16 h-16 rounded-2xl">
        <BsCodeSlash size={30} />
      </div>
      <h3 className="text-xl text-center font-semibold">{props.title}</h3>
      <p className="text-justify md:text-center text-lg">{props.description}</p>
    
    </div>
  );
};

export default ServiceCard;
