import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation,FreeMode } from "swiper/modules";


import ServiceStack from "../components/Home/ECommerceStack";
import HeroSlider from "../components/Home/HeroSlider";

import WebDev from "../videos/WebDev.mp4";
import MobileDev from "../videos/MobileDev.mp4";
import SoftProd from "../videos/SoftProd.mp4";
import DigiMrkt from "../videos/DigiMrkt.mp4";
import Cl4 from "../images/hipower.png";



import "./Home.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import AboutComp from "../components/AboutComp";
import Contact from "./Contact";
import { ProgramContext } from "../context/ProgramContext";
import ServiceCard from "../components/Services/ServiceCard";
import { Link } from "react-router-dom";
const Home = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // Scroll to the section when the component mounts
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth",block: 'start' });
    }
  }, []);

  const { programData } = useContext(ProgramContext);
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("showComp");
      } else {
        entry.target.classList.add("hideComp");
      }
    });
  });

  useLayoutEffect(() => {
    const hiddenComp = document.querySelectorAll(".hideComp");
    hiddenComp.forEach((el) => observer.observe(el));
  }, []);

  const slideData = [
    {
      imageSrc: 'https://multitechglobal.com/wp-content/uploads/2023/08/cropped-WhatsApp_Image_2023-08-18_at_3.11.40_PM__1_-removebg-preview-300x300.png',
      link: 'https://multitechglobal.com/', 
    },
    {
      imageSrc: 'https://trivenieyeplus.com/wp-content/uploads/2023/07/cropped-final-1.png',
      link: 'https://trivenieyeplus.com/',
    },
    {
      imageSrc: 'https://rubiconenterprises.in/wp-content/uploads/2023/07/rubiconlogo-300x300.png',
      link: 'http://rubiconenterprises.in/',
    },
    {
      imageSrc: Cl4,
      link: 'https://hipower.netlify.app/',
    },
  ];


  return (
    <section ref={sectionRef}>
      <HeroSlider />
      <section  className="h-16"></section>
      <section className="text-[#262e45bb] flex flex-col sm:gap-20 px-4 sm:px-28 pb-20 overflow-hidden">
        <div className="flex flex-col justify-center items-center ">
          <div className="flex justify-center items-center">
            <img
              src="https://www.weblozy.com/lp/best-web-development-company/assets/img/icon/arrow2.svg"
              className="w-24 h-32 rotate-[16deg]"
            />
          </div>
          <h2 className="text-4xl font-semibold py-5">What We Do</h2>
          <p className="text-justify sm:text-center text-base sm:text-xl ">
            Complete Web Solution brand, that helps you grow your business.
            <br /> <br /> We improve organizations' effectiveness, strengthen
            their client connections, and lift tries through a way to deal with
            <br />
            software advancement that is quantifiable, reliable, quick.
            <br /> We focus on the more modest viewpoints in conditions where an
            upset is continually approaching over us; sporadically, this could
            have a significant effect!
          </p>
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-between items-center hideComp">
          <video
            autoPlay
            loop
            muted
            src={WebDev}
            className=" w-[80%] md:w-[40%]"
          />
          <div className="md:w-[50%] text-justify">
            <h2 className="text-2xl sm:text-4xl font-semibold py-5 text-center sm:text-justify">
              Web Development
            </h2>
            <p className="text-lg max-sm:text-base">
              We specialize in providing top-notch web development solutions
              that empower businesses to establish a strong online presence. Our
              expert team creates custom websites, user-friendly e-commerce
              platforms, and powerful web applications tailored to your specific
              needs. With a client-centric approach and a keen eye for
              innovation, we deliver responsive and cutting-edge websites that
              leave a lasting impact on your audience. Let us help you unlock
              the full potential of your digital journey and drive your business
              towards success.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center hideComp ">
          <div className="md:w-[50%] text-justify">
            <h2 className="text-2xl sm:text-4xl font-semibold py-5 text-center md:text-justify">
              Mobile Development
            </h2>
            <p className="text-lg max-sm:text-base">
              At Social Syncing, we excel in delivering exceptional mobile development
              solutions that enable businesses to thrive in the mobile-driven
              era. Our skilled team crafts intuitive and engaging mobile
              applications for both Android and iOS platforms. From concept to
              deployment, we focus on user-centric design and seamless
              functionality to provide a delightful mobile experience for your
              customers. Whether you need a standalone app or integration with
              your existing systems, our mobile development expertise will
              elevate your brand's mobile presence and connect you with a
              broader audience.
            </p>
          </div>
          <video
            autoPlay
            loop
            muted
            src={MobileDev}
            className="w-[80%] md:w-[40%]"
          />
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-between items-center hideComp">
          <video
            autoPlay
            loop
            muted
            src={SoftProd}
            className="w-[80%] md:w-[40%]"
          />
          <div className="md:w-[50%] text-justify">
            <h2 className="text-2xl sm:text-4xl font-semibold py-5 text-center md:text-justify">
              Software Product
            </h2>
            <p className="text-lg max-sm:text-base">
              We take pride in developing cutting-edge software products that
              drive innovation and address your unique business challenges. Our
              expert team leverages the latest technologies and industry best
              practices to design and build robust software solutions. From
              conceptualization to deployment, we ensure the highest quality
              standards throughout the development lifecycle. Whether you
              require a standalone software or a comprehensive suite of
              applications, our software products are tailored to meet your
              specific requirements and exceed your expectations. Empower your
              business with our reliable and scalable software products designed
              to enhance efficiency and fuel growth.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center hideComp">
          <div className="md:w-[50%] text-justify">
            <h2 className="text-2xl sm:text-4xl font-semibold py-5 text-center md:text-justify">
              Digital Marketing
            </h2>
            <p className="text-lg max-sm:text-base">
              At Social Syncing, we are passionate about digital marketing and helping
              businesses succeed in the online landscape. Our dedicated team of
              digital marketing experts is well-versed in crafting data-driven
              strategies that elevate your brand's visibility, engagement, and
              conversions. From search engine optimization (SEO) and
              pay-per-click (PPC) campaigns to social media marketing and
              content creation, we employ a holistic approach to ensure your
              brand stands out amidst the competition. Let us be your trusted
              partner in achieving your digital marketing goals and driving
              measurable results for your business.
            </p>
          </div>
          <video
            autoPlay
            loop
            muted
            src={DigiMrkt}
            className="w-[80%] md:w-[40%]"
          />
        </div>
      </section>
      <section>
        <AboutComp />
      </section>
      <section className=" flex flex-col justify-center items-center lg:px-36 text-[#262e45bb]">
        <h2 className="text-4xl font-semibold py-5 text-center ">
          E-Commerce Checklist
        </h2>
        <p className="text-center text-xl">
          Our diverse range of work in various Industries
        </p>
        <ServiceStack />
      </section>
      {/* <section>
        <div className="ecomm-services w-full py-16 px-[5px] sm:px-[6%] md:px-[9%] lg:px-[5rem] bg-[#345fe0dd] ">
          <div className="w-full">
            <div className="text-center">
              <h1 className="text-3xl font-bold font-Poppins text-white tracking-wide mb-3">
                 Corporate Training
              </h1>
              <p className="text-sm font-Poppins text-white/80">
                Discover Excellence: Our Programs Await You!
              </p>
            </div>
            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              navigation={true}
              breakpoints={{
                850: { slidesPerView: 2 },
                1160: { slidesPerView: 3 },
              }}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              modules={[Pagination, Autoplay, Navigation]}
              className="mySwiper1"
              style={{ margin: "50px 0 0 0", zIndex: 0 }}
            >
              {programData.map((item, i) => {
                return (
                  <SwiperSlide
                    key={item.id}
                    style={{ background: "transparent" }}
                  >
                    <Link to={`/program/${item.id}`}>
                      <ServiceCard
                        type={"ph_card"}
                        title={item.name}
                        description={item.description}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section> */}
      <section>
       {/*  <Contact visible={false} /> */}
      </section>
      <section className=" flex flex-col justify-center items-center px-36 text-[#262e45bb]">
        {/* <h2 className="text-4xl font-semibold py-5 text-center ">
          Our Clients
        </h2> */}
        {/* <Swiper
          slidesPerView={2}
          spaceBetween={50}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          freeMode
          modules={[Pagination, Autoplay, FreeMode]}
          className="mySwiper"
          style={{ width: "70vw", height: "100px", margin: "50px 0" }}
        >

    {slideData.map((slide, index) => (
        <SwiperSlide key={index} style={{ background: "none", minWidth: "50px" }}>
          <a className="h-[100%]" href={slide.link} target="_blank" rel="noopener noreferrer">
            <img  src={slide.imageSrc} className="h-[100%]" alt={`Image ${index + 1}`} />
          </a>
        </SwiperSlide>
      ))}
        
        </Swiper> */}
      </section>
      {/* <section className="flex flex-col items-center gap-10 py-24 overflow-hidden ">
        <h2 className="text-4xl font-semibold text-[#262e45bb] inline-block px-10 py-3 rounded-full text-center">
          Testimonials
        </h2>
        <Testimonial />
      </section> */}
    </section>
  );
};

export default Home;
