import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Wave from "react-wavify";
import bg from "../../images/bg.jpeg";
import img1 from "../../images/img1.png";
import img2 from "../../images/img2.png";
import img3 from "../../images/img3.png";
import img4 from "../../images/img4.png";
import img5 from "../../images/img5.png";
import img6 from "../../images/img6.png";
import imgMain from "../../images/imgMain.png";
import "./HeroSlider.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";

const HeroSlider = () => {
  const layerEffect = (e, layer) => {
    const speed = layer.getAttribute("speed");
    const x = (window.innerWidth - e.pageX * speed) / 100;
    const y = (window.innerHeight - e.pageY * speed) / 100;
    layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
  };
  const moveHandler = (e) => {
    [...document.querySelectorAll(".hovEffect")].map((layer) => {
      layerEffect(e, layer);
    });
  };
  return (
    <>
      <Swiper
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        className="mySwiper h-[100vh_!important] sm:min-h-[102vh] w-[100vw_!important] sm:w-[99.3vw_!important]"
        style={{ overflow: "hidden" }}
      >
        <SwiperSlide style={{ height: "100%", width: "100%" }}>
          <section className=" relative w-[100%] h-[100%] flex items-center justify-center">
            <div className="bg">
              <img src={bg} className="w-full h-full" />
            </div>
            <div className=" flex flex-col items-center justify-center gap-10 sm:gap-5 z-10 px-4 text-white my-auto ">
              <h1 className="text-5xl md:text-6xl text-center font-semibold">
                Navigating the Digital Frontier
                <br />
                <span className="text-[#345fe0]">Together</span>.
              </h1>
              <p className=" text-center sm:text-start text-lg md:text-xl">
                Your Partner in Technology Excellence for a Brighter Tomorrow
              </p>
              <Link
                to={"/contact/section"}
                className="hover:bg-white hover:text-[#262e45] text-lg font-[600] py-4 px-8 rounded-full text-white bg-[#345fe0] transition-colors duration-400 z-30"
              >
                Book A Free Consultation
              </Link>
              <Wave
                fill="#4b62a355"
                paused={false}
                options={{
                  height: 10,
                  amplitude: 60,
                  speed: 0.12,
                  points: 6,
                }}
                className="absolute bottom-0"
              />
              <Wave
                fill="#4b62a355"
                paused={false}
                options={{
                  height: 10,
                  amplitude: 70,
                  speed: 0.14,
                  points: 5,
                }}
                className="absolute bottom-0"
              />
              <Wave
                fill="#4b62a355"
                paused={false}
                options={{
                  height: 10,
                  amplitude: 40,
                  speed: 0.16,
                  points: 6,
                }}
                className="absolute bottom-0"
              />
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide
          style={{ height: "100%", width: "100%" }}
          className="bg-[#1f2a4e_!important] overflow-hidden"
        >
          <section
            className=" relative  w-[100%] h-[110%] flex flex-col-reverse gap-5 sm:flex-row items-center justify-center"
            onMouseMove={moveHandler}
          >
            <div className="">
              <div className="shadow-[200px_200px_400px_150px_#345fe077] absolute left-10 top-32 rounded-full w-10 -z-10"></div>
              <div className="shadow-[200px_200px_400px_150px_#345fe077] absolute right-[40rem] top-10 rounded-full w-10 -z-10"></div>
              <div className="shadow-[400px_200px_500px_370px_#345fe077] absolute right-[32rem] bottom-56 rounded-full w-10 -z-10"></div>
            </div>
            <div className=" flex flex-col items-center justify-center gap-5 z-10 px-4 sm:py-60 text-white sm:w-[50%]">
              <h1 className="text-4xl md:text-6xl text-center font-semibold">
                <span className="text-[#C3BCFB]">Empowering Businesses </span>
                <br />
                <span className="text-[#F27875]">Through Innovation</span>
              </h1>
              <p className="text-xl w-[75%] text-center text-[#9DD4C0]">
                Unleash the Potential of Technology with Social Syncing's Expert
                Solutions
              </p>
              <Link
                to={"/contact"}
                className="hover:bg-white text-[#161b2c] text-lg font-[600] py-4 px-8 rounded-full  bg-[#C3BCFB] transition-colors duration-400 "
              >
                Book A Free Consultation
              </Link>
            </div>
            <div className="w-[50%] md:relative md:h-[70vh]">
              <img
                src={img1}
                className="hovEffect absolute w-24 left-16 top-0 z-10 hidden md:block"
                speed={5}
              />
              <img
                src={img2}
                className="hovEffect absolute w-32 left-8 top-48 hidden md:block"
                speed={3}
              />
              <img
                src={img3}
                className="hovEffect absolute w-28 left-16 top-80 hidden md:block"
                speed={5}
              />
              <img
                src={img4}
                className="hovEffect absolute w-32 right-12 top-0 z-10 hidden md:block"
                speed={3}
              />
              <img
                src={img5}
                className="hovEffect absolute w-20 right-16 top-36 hidden md:block"
                speed={5}
              />
              <img
                src={img6}
                className="hovEffect absolute w-28 right-16 top-80 hidden md:block"
                speed={3}
              />
              <img
                src={imgMain}
                className="hovEffect static md:absolute w-[28rem] left-28"
                speed={1}
              />
            </div>
          </section>
        </SwiperSlide>
        <button className="prev">prev</button>
        <button className="next">Next</button>
      </Swiper>
    </>
  );
};

export default HeroSlider;
