import React, { useLayoutEffect } from "react";
import "./ECommerceStack.css";
import img1 from "../../images/E-commerce_Checklist/img1.png";
import img2 from "../../images/E-commerce_Checklist/img2.png";
import img3 from "../../images/E-commerce_Checklist/img3.png";
import img4 from "../../images/E-commerce_Checklist/img4.png";
import img5 from "../../images/E-commerce_Checklist/img5.png";
import img6 from "../../images/E-commerce_Checklist/img6.png";
import img7 from "../../images/E-commerce_Checklist/img7.png";
import img8 from "../../images/E-commerce_Checklist/img8.png";
import img9 from "../../images/E-commerce_Checklist/img9.png";
import img10 from "../../images/E-commerce_Checklist/img10.png";

const ServiceStack = () => {
  const eCommerceData = [
    {
      title: "Electronics and Gadgets Store",
      description:
        "Discover cutting-edge electronics and gadgets in our online store. Explore a wide range of tech products, from smart devices to accessories, and enjoy secure payments and detailed product insights.",
      image: img1,
    },
    {
      title: "Fashion and Apparel Boutique",
      description:
        "Step into a world of style with our fashion boutique. Find the latest trends in clothing and accessories for all ages, complete with stunning visuals, easy navigation, and a seamless checkout experience.",
      image: img2,
    },
    {
      title: "Health and Wellness Shop",
      description:
        "Elevate your well-being through our wellness store. Explore an array of health supplements, fitness gear, and self-care products, all supported by informative descriptions, customer reviews, and subscription options.",
      image: img3,
    },
    {
      title: "Home Decor and Furniture Store",
      description:
        "Transform your space with our curated home decor collection. Browse furniture and decor that reflect your style, featuring high-quality imagery, room planning tools, and hassle-free delivery choices.",
      image: img4,
    },
    {
      title: "Beauty and Cosmetics Store",
      description:
        "Experience beauty in every shade through our cosmetics store. Discover makeup and skincare essentials with detailed ingredient insights, user testimonials, and personalized product recommendations.",
      image: img5,
    },
    {
      title: "Food and Gourmet Store",
      description:
        "Indulge your taste buds in our gourmet food emporium. Sample delectable treats and specialty ingredients, all packaged with care and complemented by freshness assurances and delectable recipes.",
      image: img6,
    },
    {
      title: "Books and Stationery Shop",
      description:
        "Immerse yourself in the world of words through our book and stationery shop. Browse diverse reads, stationery supplies, and engaging author insights, all designed to enrich your reading journey.",
      image: img7,
    },
    {
      title: "Toys and Games Store",
      description:
        "Ignite imagination and learning with our toys and games store. Discover toys that entertain and educate, supported by parent reviews, interactive demos, and age-appropriate recommendations.",
      image: img8,
    },
    {
      title: "Sports and Fitness Equipment Store",
      description:
        "Energize your fitness journey with our sports equipment offerings. Explore a variety of gear, equipped with detailed specifications, expert advice, and user-generated workout inspiration.",
      image: img9,
    },
    {
      title: "Outdoor Gear and Adventure Equipment",
      description:
        "Embark on thrilling journeys with our outdoor gear store. Explore a range of adventure equipment backed by durability guarantees, user-generated content, and guided product selection for your expeditions.",
      image: img10,
    },
  ];
  useLayoutEffect(() => {
    const cardCol = document.getElementById("cards-col");
    const cards = document.querySelectorAll(".card");
    const card_bodies = document.querySelectorAll(".card-body");

    cardCol.style.setProperty("--len", cards.length);
    cards.forEach((card, index) => {
      if (index != cards.length - 1) {
        card.style.setProperty("--index", index + 1);
        card.style.setProperty("--len", cards.length);
      } else {
        console.log("It's last card");
        card.classList.add("py-[1.2rem_!important]");
      }
    });
    card_bodies.forEach((card_body, index) => {
      card_body.classList.add(
        `${index % 2 == 0 ? "card-body1" : "card-body2"}`
      );
    });
  }, []);

  return (
    <div className="container">
      <ul id="cards-col" className="cards-col">
        {eCommerceData.map((data) => (
          <li className="card max-sm:px-[1.2rem_!important]">
            <div className="card-body max-sm:flex-col max-sm:p-10 ">
              <div className="sm:w-[50%] flex items-center justify-center">
                <img src={data.image} />
              </div>
              <div className="sm:w-[40%] flex flex-col justify-between gap-7">
                <h3 className="text-2xl sm:text-3xl font-bold">{data.title}</h3>
                <p className="sm:text-lg">{data.description}</p>
                <button className="w-[150px] bg-white hover:bg-[#262e45bb] text-[#262e45bb] hover:text-white  rounded-full py-2 font-semibold text-lg">
                  Get Started
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServiceStack;
