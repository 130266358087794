import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bg from "../images/data_science.jpeg";

export const ProgramContext = createContext();

const ProgramStates = (props) => {
  const cardContent=[
  "21+ Hours Of Corporate Technical Training",
  "Teaching Assistance",
  "Interview Prep & Soft Skills Training",
  "Client Project",
  ]
  const free=[
    "Industry level Technical Course",
    "Teaching assistance",
    "Soft skills training sessions",
    "Resume building sessions",
  ]
  const selfPaced=[
    "1 Client Project",
    "Progress Tracking Meetings",
    "Mentorship", 
    "Corporate Exposure"
  ]
  const selfPacedPlus=["Performance Based Stipend", 
  "Internship Completion Certificate", 
  "Course Completion Certificate", 
  "Letter of Recommendation"]
  const programData = [
    {
      id: 1,
      name: "MERN Stack Web Development",
      description:
        "MERN (MongoDB, Express.js, React, Node.js) Stack Development is a full-stack web development approach that combines these technologies to create robust, dynamic web applications.",
      bgImage: bg,
      cardContent:cardContent,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 2,
      name: "Mobile App Development",
      description:
        "Mobile App Development involves designing and building applications for smartphones and tablets, catering to both iOS and Android platforms, to provide engaging and functional user experiences.",
      bgImage: bg,
      cardContent:cardContent,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus  ,
    },
    {
      id: 3,
      name: "Front End Development",
      description:"Front End Development focuses on creating the user interface and user experience of a website or web application. It involves HTML, CSS, JavaScript, React and Redux to make websites visually appealing and interactive.",
      bgImage: bg,
      cardContent:cardContent ,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 4,
      name: "Backend Development",
      description:"Backend Development involves building the server-side logic and infrastructure that powers websites and applications, handling data storage, security, and server operations.",
      bgImage: bg,
      cardContent:cardContent ,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 5,
      name: "Data Science with Python",
      description:"Data Science is the field of extracting insights and knowledge from large datasets using various techniques, including data analysis, machine learning, and statistical modeling.",
      bgImage: bg,
      cardContent:cardContent ,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 6,
      name: "Business Analytics",
      description:"Business Analytics involves using data analysis and statistical methods to make informed business decisions, optimize operations, and gain a competitive edge in the market.",
      bgImage: bg,
      cardContent:cardContent ,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 7,
      name: "AI/ML (Artificial Intelligence/Machine Learning)",
      description:"AI/ML focuses on creating algorithms and systems that enable computers to learn and make decisions, leading to advancements in automation, prediction, and problem-solving.",
      bgImage: bg,
      cardContent:cardContent,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 8,
      name: "Java Developer",
      description:"Java Developers work with the Java programming language to build applications, ranging from web and mobile apps to enterprise-level software.",
      bgImage: bg,
      cardContent:cardContent,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 9,
      name: "UI/UX (User Interface/User Experience)",
      description:"UI/UX professionals design and optimize the visual and interactive elements of websites and applications, ensuring a user-friendly and enjoyable experience.",
      bgImage: bg,
      cardContent:cardContent ,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },
    {
      id: 10,
      name: "Digital Marketing",
      bgImage: bg,
      description:"Digital Marketing involves promoting products or services using digital channels such as social media, search engines, email, and content marketing to reach a wider online audience and drive business growth.",
      cardContent:cardContent ,
      enrollments: 1300,
      review: 200,
      view: 400,
      free:free ,
      selfPaced:selfPaced ,
      selfPacedPlus:selfPacedPlus ,
    },

  ];

  return (
    <ProgramContext.Provider value={{ programData }}>
      {props.children}
    </ProgramContext.Provider>
  );
};

export default ProgramStates;
