import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const OnboardingForm = (props) => {
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleOnChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
    >
      <div
        className="fixed top-0 left-0 w-full h-full bg-[#000000aa] backdrop-blur-md cursor-pointer"
        onClick={() => {
          props.showHandler(false);
        }}
      ></div>
      <div className="w-full h-[90%] sm:w-[60%] md:w-[35%] bg-white rounded-3xl animate-swipeIn relative">

      <form className="flex flex-col justify-center gap-5 p-7 h-full shadow-xl">
  <AiOutlineClose
    color="black"
    size={25}
    className="absolute right-7 top-7 cursor-pointer"
    onClick={() => {
      props.showHandler(false);
    }}
  />
  <div className="text-[#345fe0]">
    <h3 className="text-md text-blue-500">Corporate Training</h3>
    <h2 className="text-3xl font-semibold text-blue-500">Onboarding Form</h2>
  </div>
  <input
    name="name"
    onChange={handleOnChange}
    type="text"
    placeholder="Name"
    className="bg-white text-black p-2 rounded-lg focus:outline-none"
    required
  />

  <input
    name="email"
    onChange={handleOnChange}
    type="email"
    placeholder="Email address"
    className="bg-white text-black p-2 rounded-lg focus:outline-none"
    required
    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
  />

  <input
    name="residence"
    onChange={handleOnChange}
    type="text"
    placeholder="Place of residence"
    className="bg-white text-black p-2 rounded-lg focus:outline-none"
    required
  />

  <input
    name="contactNumber"
    onChange={handleOnChange}
    type="tel"
    placeholder="Contact number"
    className="bg-white text-black p-2 rounded-lg focus:outline-none"
    required
    pattern="[0-9]{10}"
  />

  <input
    name="college"
    onChange={handleOnChange}
    type="text"
    placeholder="College"
    className="bg-white text-black p-2 rounded-lg focus:outline-none"
    required
  />

  <div className="bg-white p-2 rounded-lg focus:outline-none">
    <label>Degree:</label>
    <div className="radio-group">
      <input
        type="radio"
        id="degree-graduated"
        name="degree"
        value="Graduated"
        checked={value.degree === 'Graduated'}
        onChange={handleOnChange}
        required
      />
      <label htmlFor="degree-graduated" className="text-black">
        Graduated
      </label>

      <input
        type="radio"
        id="degree-pursuing"
        name="degree"
        value="Pursuing"
        checked={value.degree === 'Pursuing'}
        onChange={handleOnChange}
        required
      />
      <label htmlFor="degree-pursuing" className="text-black">
        Pursuing
      </label>
    </div>
  </div>

  <div className="form-group">
    <label htmlFor="domain" className="text-black">
      Preferred Domain:
    </label>
    <select
      id="domain"
      name="preferredDomain"
      value={value.preferredDomain}
      onChange={handleOnChange}
      required
      className="bg-white text-black"
    >
      <option value="">Select an option</option>
      <option value="MERN Stack Development">MERN Stack Development</option>
      <option value="Front End Development">Front End Development</option>
      <option value="Backend Development">Backend Development</option>
      <option value="Mobile App Development">Mobile App Development</option>
      <option value="UI/UX">UI/UX</option>
      <option value="Digital Marketing">Digital Marketing</option>
      <option value="Data Science">Data Science</option>
      <option value="AI/ML">AI/ML</option>
      <option value="Business Analytics">Business Analytics</option>
      <option value="Java Developer">Java Developer</option>
    </select>
  </div>

  <a
    href={`mailto:contact@socialsyncing.in?subject=${
      "Message from " + value.name
    }&body=${
      encodeURIComponent(value.message) +
      "%0AName: " +
      value.name +
      "%0AContact no: " +
      value.phone +
      "%0AEmail: " +
      value.email
    }`}
    onClick={() => {
      console.log(value.message);
    }}
    className="hover:bg-[#345fe0] hover:text-white text-lg font-semibold py-2 mx-auto px-8 rounded-xl text-white bg-blue-500 transition-colors duration-400 cursor-pointer"
  >
    Send Message
  </a>
</form>
      </div>
    </div>
  );
};

export default OnboardingForm;


